// src/BetCard.js
import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import './BetCard.css';
import './CircularProgressBar.css';

const HalfCircleProgressBar = ({ value }) => {
  // Ensure the value is between 0 and 100
  const safeValue = Math.min(100, Math.max(0, value));

  // Calculate the stroke-dasharray for the progress (half-circle)
  const radius = 45; // The radius of the circle
  const circumference = Math.PI * radius; // Only half of the full circumference
  const offset = circumference - (safeValue / 100) * circumference;

  // Define softer colors for values below and above 50
  const lowColor = 'rgba(255, 99, 71, 0.8)'; // Soft red (Tomato)
  const highColor = 'rgba(60, 179, 113, 0.8)'; // Soft green (MediumSeaGreen)

  // Determine the gradient based on the value
  const gradientColor = safeValue < 50 ? lowColor : highColor;

  return (
    <div className="progress-container">
      <svg width="100" height="60" viewBox="0 0 120 60">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="100%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: gradientColor }} />
            <stop offset="100%" style={{ stopColor: gradientColor }} />
          </linearGradient>
        </defs>
        <path
          d="M 10,50 A 40,40 0 0 1 110,50"
          fill="none"
          stroke="#e0e0e0"
          strokeWidth="10"
          strokeLinecap="round"
        />
        <path
          d="M 10,50 A 40,40 0 0 1 110,50"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      <div className="progress-text">{safeValue}%</div>
      <div className="label">chance</div>
    </div>
  );
};


const CardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  max-width: 400px;
  margin: 20px auto;
`;

const QuestionText = styled.h3`
  font-size: 18px;
  color: #333;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const BetButton = styled.button`
  background-color: ${(props) => (props.yes ? '#4CAF50' : '#f44336')};
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.yes ? '#45a049' : '#e57373')};
  }
`;

const BetCard = ({question, sendMoney}) => {
  const [selectedBet, setSelectedBet] = useState(null); // null means no selection yet
  const [betValue, setBetValue] = useState(10); // Default bet value is $10

  // Handle clicking on Bet Yes
  const handleBetYes = () => {
    setSelectedBet('yes');
    // if (onBetYes) {
    //   onBetYes(betValue); // Call external handler if provided
    // }
  };

  const handleBetErase = () => {
    setSelectedBet(null);
    // if (onBetYes) {
    //   onBetYes(betValue); // Call external handler if provided
    // }
  };

  // Handle clicking on Bet No
  const handleBetNo = () => {
    setSelectedBet('no');
    // if (onBetNo) {
    //   onBetNo(betValue); // Call external handler if provided
    // }
  };

  // Handle bet value change
  const handleBetValueChange = (event) => {
    setBetValue(parseInt(event.target.value, 10));
  };

  // Handle slider change
  const handleSliderChange = (event) => {
    setBetValue(parseInt(event.target.value, 10));
  };

  function calculateWinnings(betAmount, chancePercentage) {
    // Ensure that chancePercentage is a valid percentage between 0 and 100
    if (chancePercentage < 0 || chancePercentage > 100) {
      throw new Error("Chance percentage must be between 0 and 100");
    }
  
    // Calculate the winnings
    const winnings = (betAmount * (100 / chancePercentage));
  
    // Return the winnings
    return winnings;
  }

  if(selectedBet === null) {
    return (
      <div className='betCard'>
          <div className='betCard-header'>
              <img className='betCard-header-img' src='https://icdn.lenta.ru/images/2023/05/01/15/20230501150535376/owl_detail_240_b5a1fb4c432b35903d5bd16b0e542b5a.jpg' />
              <p className='betCard-header-text'>{question}</p>
              <HalfCircleProgressBar value={64} />
          </div>
          <div className='betCard-action'>
              <button className='betCard-button-yes' onClick={handleBetYes}>
                  <p style={{margin: 7}}>Bet Yes</p>
              </button>
              <button className='betCard-button-no'  onClick={handleBetNo}>
                  <p style={{margin: 7}}>Bet No</p>
              </button>
          </div>
          <div className='betCard-stats'>
              <span>12 Reposts</span>
              <span>$210k Bet</span>
          </div>
      </div>
    )
  }
  return (
    <div className='betCard'>
        <div className='betCard-header'>
            <img className='betCard-header-img' style={{width: '32px', height: '32px'}} src='https://icdn.lenta.ru/images/2023/05/01/15/20230501150535376/owl_detail_240_b5a1fb4c432b35903d5bd16b0e542b5a.jpg' />
            <p className='betCard-header-text' style={{fontSize: '16px'}}>{question}</p>
            {/* <HalfCircleProgressBar value={64} /> */}
            <button class="close-button" onClick={ handleBetErase}>&times;</button>
        </div>
        <div className='betCard-value-input'>
          <div className='betCard-input-container'>
            <input
              type='number'
              value={betValue}
              onChange={handleBetValueChange}
              className='betCard-input'
            />
            <div className='betCard-buttons'>
              <button onClick={() => setBetValue(betValue + 1)}>+1</button>
              <button onClick={() => setBetValue(betValue + 10)}>+10</button>
            </div>
          </div>
          <input
            type='range'
            min='0'
            max='1000'
            value={betValue}
            onChange={handleSliderChange}
            className='betCard-slider'
          />
        </div>
        {/* <div className='betCard-action'>
            <button className='betCard-button-yes' onClick={handleBetYes}>
                <p style={{margin: 7}}>Bet Yes</p>
            </button>
            <button className='betCard-button-no'  onClick={handleBetNo}>
                <p style={{margin: 7}}>Bet No</p>
            </button>
        </div> */}
        <div className='betCard-action' style={{marginTop: '0px'}}>
            <button className={`betCard-button ${selectedBet}`} onClick={() => sendMoney('1000000', 'TON')}>
                <p >{selectedBet === 'yes' ? 'Bet Yes': 'Bet No'}</p>
                <span className='small'>{`To win ${calculateWinnings(betValue, 64)}`}</span>
            </button>
        </div>

        
    </div>
  )
}

const BetCard2 = ({ question, onBetYes, onBetNo }) => {
  return (
    <CardContainer>
      <QuestionText>{question}</QuestionText>
      <ButtonGroup>
        <BetButton yes onClick={onBetYes}>BET Yes</BetButton>
        <BetButton onClick={onBetNo}>BET No</BetButton>
      </ButtonGroup>
    </CardContainer>
  );
};

export default BetCard;