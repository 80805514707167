// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const manifestUrl = 'https://betwithton.com/tonconnect-manifest.json'; // Your manifest URL

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TonConnectUIProvider manifestUrl={manifestUrl} actionsConfiguration={{
    twaReturnUrl: 'https://t.me/BetTrust_bot/betwithton'
}}>
    <App />
   </TonConnectUIProvider>
);