// // src/App.js
import React, { useState, useEffect } from 'react';
import { TonConnectButton, useTonConnectUI, useTonWallet  } from '@tonconnect/ui-react';
import styled from 'styled-components';
import BetCard from './BetCard';


const ConnectWallet = () => {
  const tonConnectUI = useTonConnectUI();
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (!tonConnectUI.connected) {
      setIsConnecting(true);
      tonConnectUI.connectWallet()  // This function triggers the connection process
        .finally(() => setIsConnecting(false));
    }
  }, [tonConnectUI]);

  return (
    <div>
      <h1>Connect Your Wallet</h1>
      {tonConnectUI.connected ? (
        <p>Your wallet is connected!</p>
      ) : (
        <div>
          {isConnecting ? (
            <p>Connecting to your Telegram wallet...</p>
          ) : (
            <TonConnectButton />
          )}
        </div>
      )}
    </div>
  );
};

function App() {
  const wallet = useTonWallet(); // Hook to get the connected wallet
  const [tonConnectUI] = useTonConnectUI()
  // const [amount, setAmount] = useState('');
  const recipient = 'UQDjPdJVwPSLy0s9ujKCvzdwUl4K48Xbj5Xk5t12vwwpLbj7';  // Replace with your wallet address

  const sendTransaction = async (amount, token) => {
    if (!wallet) {
        alert('Please connect your wallet first!');
        return;
    }

    const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec   
        messages: [
            {
                address: recipient, // Destination address
                amount: amount,  // The amount to send in nanotons (1 TON = 1e9 nanotons)
                // payload: token === 'TON' ? undefined : 'USDT_TRANSFER_PAYLOAD',  // Placeholder for USDT payload
            }
        ]
    };

    try {
        await tonConnectUI.sendTransaction(transaction);
        alert('Transaction sent successfully!');
    } catch (error) {
        console.error('Failed to send transaction', error);
        alert('Failed to send transaction. Please try again.');
    }
    };

  return (
    <div>
      <BetCard
        question="Pavel Durov released in August?"
        sendMoney={sendTransaction}
        // onBetYes={()=>{}}
        // onBetNo={()=>{}}
      /> 
      <TonConnectButton />
      {/* <ConnectWallet /> */}
    </div>
  );
}

export default App;

// import React, { useEffect, useState } from 'react';
// import TonConnect from '@tonconnect/sdk';
// import BetCard from './BetCard';

// const App = () => {
//     const [wallet, setWallet] = useState(null);
//     const [isConnected, setIsConnected] = useState(false);

//     useEffect(() => {
//         const connectWallet = async () => {
//             const connector = new TonConnect({
//                 manifestUrl: 'https://betwithton.com/tonconnect-manifest.json',
//             });

//             try {
//                 // Fetch wallets list
//                 const walletsList = await TonConnect.getWallets();
//                 console.log('Available wallets:', walletsList);
                
//                 // Find the Telegram wallet
//                 const telegramWallet = walletsList.find(wallet => wallet.appName === 'telegram-wallet');

//                 if (telegramWallet) {
//                     // Attempt to connect using the universal link if available
//                     const link = await connector.connect({
//                         universalLink: telegramWallet.universalLink,
//                         bridgeUrl: telegramWallet.bridgeUrl,
//                     });

//                     // Redirect to the wallet connection link
//                     window.location.href = link;

//                     // Listen for connection status changes
//                     connector.onStatusChange(walletInfo => {
//                         if (walletInfo) {
//                             setWallet(walletInfo);
//                             setIsConnected(true);
//                         }
//                     });

//                 } else {
//                     console.error("Telegram wallet not found or does not support universal link");
//                 }
//             } catch (error) {
//                 console.error("Failed to connect to Telegram wallet", error);
//             }
//         };

//         connectWallet();
//     }, []);

//     return (
//         <div>
//             {isConnected ? (
//                 <BetCard
//                         question="Pavel Durov released in August?"
//                         onBetYes={()=>{}}
//                         onBetNo={()=>{}} />
//             ) : (
//                 <h1>Connecting to Telegram Wallet...</h1>
//             )}
//         </div>
//     );
// };

// export default App;

